
import './index.scss'
import 'animate.css';
import { VStack, Center, Container, Text, Image } from '@chakra-ui/react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { Parallax } from 'react-parallax';

const Products = () => {

    return (
        <Parallax blur={10} bgImage={require('../../assets/images/products.png')} bgImageAlt="the cat" className={'product-container'} strength={800} >
            <VStack py={24} bgColor={"rgba(255, 255, 255,0.7)"} h={'100%'}>
                <Center pb={34}>
                    <Text className={'header'} fontSize={'2.4vh'}>Products</Text>
                </Center>
                <Container maxW='container.xxl' color='#262626' mb={24} >
                    {/* <Container color='#262626' mb={24} > */}

                    <Center>
                        <div className={'appItems'}>
                            <button onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.pentestkit', '_blank');
                            }}>
                                <VStack >
                                    <Image
                                        src={require('../../assets/images/vuln.png')}
                                        height={110}
                                        width={110}
                                        alt="Pentestkit Mobile"
                                    />
                                    <Text fontSize='lg' fontWeight={"500"}>Pentestkit Mobile</Text>
                                    <Text>Ratings: 5.0 ⭐⭐⭐⭐⭐</Text>
                                    <Text>Downloads: +50,000</Text>
                                </VStack>
                            </button>
                            <button onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.jlt.weguitar', '_blank');
                            }}>
                                <VStack >
                                    <Image
                                        src={require('../../assets/images/opm.png')}
                                        height={110}
                                        width={110}
                                        style={{ borderRadius: 12 }}
                                        alt="Weguitar"
                                    />
                                    <Text fontSize='lg' fontWeight={"500"}>WeGuitar</Text>
                                    <Text>Ratings: 4.8 ⭐⭐⭐⭐⭐</Text>
                                    <Text>Downloads: +50,000</Text>

                                </VStack>
                            </button>
                            <button
                                onClick={() => {
                                    window.open('https://play.google.com/store/apps/details?id=com.camtextScanner', '_blank');
                                }}
                            >
                                <VStack >
                                    <Image
                                        src={require('../../assets/images/camtext.png')}
                                        height={110}
                                        width={110}
                                        alt="CamText"
                                    />
                                    <Text fontSize='lg' fontWeight={"500"}>CamText Scanner</Text>
                                    <Text>Ratings: 3.0 ⭐⭐⭐</Text>
                                    <Text>Downloads: +100</Text>
                                </VStack>
                            </button>
                            <button onClick={() => {
                                window.open('https://github.com/thejolotoproject/blind-rce', '_blank');
                            }}>
                                <VStack >
                                    <Image
                                        src={require('../../assets/images/rce.png')}
                                        height={110}
                                        width={110}
                                        borderRadius={20}
                                        alt="RCE"
                                    />
                                    <Text fontSize='lg' fontWeight={"500"}>Time Based / Blind Remote Code Execution</Text>
                                    <Text>Type: CLI</Text>
                                </VStack>
                            </button>
                            <button onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.simpleGuitarTuner', '_blank');
                            }}>
                                <VStack >
                                    <Image
                                        src={require('../../assets/images/noimage.png')}
                                        height={110}
                                        width={110}
                                        alt="Simple Guitar"
                                    />
                                    <Text fontSize='lg' fontWeight={"500"}>Simple Guitar Tuner</Text>
                                    <Text>Ratings: No ratings yet.</Text>
                                    <Text>Pause</Text>
                                </VStack>
                            </button>


                        </div>

                    </Center>
                </Container>
            </VStack>
        </Parallax>
    )
}

export default Products